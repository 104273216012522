import { useEffect, useState } from 'react'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { GET_TIMER, ON_SET_TIMER } from '../graphql/queries'

const useTimer = (gameId) => {
  const [timer, setTimer] = useState(null)
  useQuery(GET_TIMER, {
    variables: { id: gameId },
    onCompleted: (data) => {
      if (data && data.getTimer) {
        setTimer(data.getTimer.timer)
      }
    },
  })
  useSubscription(ON_SET_TIMER, {
    variables: { id: gameId },
    onSubscriptionData: ({ subscriptionData }) => {
      setTimer(subscriptionData.data.onSetTimer.timer)
    },
  })

  const [now, setNow] = useState(new Date())
  useEffect(() => {
    function tick() {
      setNow(new Date())
    }

    const timerID = setInterval(() => tick(), 1000)

    return () => {
      clearInterval(timerID)
    }
  }, [])

  return {
    duration: timer ? timer.duration : false,
    progressBarValue: timer
      ? (now.getTime() - new Date(timer.start).getTime()) / 1000
      : 0,
  }
}

export default useTimer
