import { Link } from 'gatsby'
import React, { useState } from 'react'
import Timer from '../Timer/Timer'
import './Header.scss'
import ProgressBar from '../Timer/ProgressBar/ProgressBar'
import ShowRoles from './ShowRoles/ShowRoles'
import { STATE_ROLES_GIVEN } from '../../config/states'
import { getClasses } from '../../utils'
import LayoutCenter from '../../wal-common/src/components/Layout/LayoutCenter/LayoutCenter'

const classes = getClasses('Header')

function Header({ title, game, me }) {
  const [isActive, setIsActive] = useState(false)

  return (
    <>
      <nav
        {...classes(null, null, 'navbar')}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <div {...classes('Brand')}>
            <h1 className="title is-4 is-marginless">
              <Link className="navbar-item" to="/">
                {title}
              </Link>
            </h1>
            <div {...classes('BrandRight')}>
              <LayoutCenter>
                {game && game.state === STATE_ROLES_GIVEN && me && (
                  <ShowRoles />
                )}
              </LayoutCenter>
            </div>
          </div>

          <a
            role="button"
            {...classes('Burger', null, {
              'navbar-burger': true,
              burger: true,
              'is-active': isActive,
            })}
            tabIndex={0}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={(e) => {
              e.preventDefault()
              setIsActive(!isActive)
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navMenu"
          {...classes('Menu', null, {
            'navbar-menu walEffectSlideDown walEffectSlideDown--touch': true,
            'is-active': isActive,
          })}
        >
          <div className="navbar-end">
            <div className="navbar-item">
              {game && me && <Timer game={game} />}
            </div>
          </div>
        </div>
      </nav>
      <ProgressBar gameId={game ? game.id : undefined} />
    </>
  )
}

export default Header
