import React from 'react'
import BEMHelper from 'react-bem-helper'
import './IconButon.scss'

const classes = new BEMHelper({
  name: 'IconButton',
})

const IconButton = ({
  Icon,
  url,
  modifier,
  addClasses = ['is-primary'],
  onClick = () => {},
}) => {
  return (
    <span {...classes(null, modifier)}>
      <a
        data-testid="iconbutton"
        href={url}
        {...classes('Button', null, ['button', ...addClasses])}
        onClick={onClick}
      >
        <span {...classes('Icon', null, 'icon is-medium')}>
          <Icon size="1.5rem" />
        </span>
      </a>
    </span>
  )
}

export default IconButton
