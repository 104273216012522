import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actionSetShowRoles } from '../../../store/actions'
import { getClasses } from '../../../utils'
import './ShowRoles.scss'
const classes = getClasses('ShowRoles')

function ShowRoles() {
  const showRoles = useSelector(({ diary }) => diary.showRoles)
  const dispatch = useDispatch()

  return (
    <label {...classes(null, null, 'checkbox')}>
      <div {...classes('Input')}>
        <input
          aria-label="show roles"
          type="checkbox"
          data-testid="showRoles"
          checked={showRoles}
          onChange={(e) => {
            dispatch(actionSetShowRoles(e.target.checked))
          }}
        />
      </div>
      <div className="help">
        показать
        <br />
        роли
      </div>
    </label>
  )
}

export default ShowRoles
