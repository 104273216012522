import React, { useState } from 'react'
import { FaPlay, FaStop } from 'react-icons/fa'
import IconButton from '../../wal-common/src/components/Icon/IconButton/IconButton'
import './Timer.scss'
import { useMutation } from '@apollo/react-hooks'
import { SET_TIMER } from '../../graphql/queries'
import { formatNumber } from '../../utils'
import BEMHelper from 'react-bem-helper'
import useTimer from '../../hooks/useTimer'

const classes = new BEMHelper({
  name: 'Timer',
})

const durations = [60, 5 * 60, 15 * 60, 30 * 60]

const defaultDuration = 15 * 60

function Timer({ game }) {
  const gameId = game.id

  const [setTimer] = useMutation(SET_TIMER)
  const [selectedDuration, setSelectedDuration] = useState(defaultDuration)

  const { progressBarValue, duration } = useTimer(gameId)

  let cdTotalNegative = false
  let cdMin = 0
  let cdSec = 0

  if (duration) {
    const cdTotal = duration - progressBarValue
    let cdTotalAbs = Math.floor(cdTotal)
    if (cdTotal < 0) {
      cdTotalAbs = Math.floor(-cdTotal)
      cdTotalNegative = true
    }

    cdMin = Math.floor(cdTotalAbs / 60)
    cdSec = cdTotalAbs % 60
  }

  return (
    <div data-testid="timer" {...classes()}>
      <div {...classes('Container')}>
        <div className="m-r-md">
          <IconButton
            onClick={() => {
              setTimer({
                variables: {
                  gameId,
                  duration: duration ? null : selectedDuration,
                },
              })
            }}
            Icon={duration ? FaStop : FaPlay}
          />
        </div>
        {duration && (
          <div
            data-testid="countdown"
            {...classes(
              'Countdown',
              {
                blink: cdTotalNegative,
              },
              {
                'has-text-primary': !cdTotalNegative,
                'has-text-danger': cdTotalNegative,
              }
            )}
          >
            {cdTotalNegative && '-'}
            {formatNumber(cdMin)}:{formatNumber(cdSec)}
          </div>
        )}
        {!duration && (
          <div className="control Timer__Options">
            {durations.map((dur) => (
              <label key={dur} className="radio">
                <input
                  aria-label="Duration"
                  type="radio"
                  name="duration"
                  value={dur}
                  checked={dur === selectedDuration}
                  onChange={(e) => {
                    setSelectedDuration(parseInt(e.target.value))
                  }}
                />{' '}
                {dur / 60} min
              </label>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default Timer
