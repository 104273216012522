import React from 'react'
import { getClasses } from '../../../utils'
import './ProgressBar.scss'
import useTimer from '../../../hooks/useTimer'
import useAudio from '../../../hooks/useAudio'

const playNotificationSoundAt = [5 * 60, 60, 0]

const classes = getClasses('ProgressBar')

function ProgressBar({ gameId }) {
  const [playing, , start] = useAudio('/media/get-outta-here.mp3')

  const { progressBarValue, duration } = useTimer(gameId)
  let cdTotalNegative = false

  if (duration) {
    const cdTotal = duration - progressBarValue
    let cdTotalAbs = Math.floor(cdTotal)
    if (cdTotal < 0) {
      cdTotalAbs = Math.floor(-cdTotal)
      cdTotalNegative = true
    }

    if (!cdTotalNegative && playNotificationSoundAt.includes(cdTotalAbs)) {
      if (!playing) start()
    }
  }

  return (
    <div {...classes()}>
      <progress
        value={progressBarValue}
        max={duration || 100}
        {...classes(
          'Progress',
          {
            blink: cdTotalNegative,
          },
          'progress is-primary'
        )}
      />
    </div>
  )
}

export default ProgressBar
