import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SEO from './seo'
import './layout.scss'
import * as PropTypes from 'prop-types'
import Header from './Header/Header'

Header.propTypes = { data: PropTypes.any }
const Layout = ({ children, game, me }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <SEO
            title={data.site.siteMetadata.title}
            keywords={[`Mafia`, `Boardgame`]}
          />
          <main className="container Layout">
            <Header title={data.site.siteMetadata.title} game={game} me={me} />
            <div className="Layout__Main">{children}</div>
          </main>
        </>
      )}
    />
  )
}

export default Layout
